import React from 'react';

const EmployeeDataDisplay = ({ data }) => {
  const renderTableSection = (categories) => (
    <table className="min-w-full border-collapse border border-gray-200 mt-8">
      <thead>
        <tr className="bg-gray-200">
          <th className="border border-gray-300 p-2" rowSpan="2">Age Group</th>
          {categories.map((category) => (
            <th key={category} className="border border-gray-300 p-2 capitalize" colSpan="3">{category}</th>
          ))}
        </tr>
        <tr className="bg-gray-200">
          {categories.flatMap(() => [
            <th key="recruits" className="border border-gray-300 p-2">New recruits</th>,
            <th key="left" className="border border-gray-300 p-2">Employees left</th>,
            <th key="total" className="border border-gray-300 p-2">Total Employees</th>
          ])}
        </tr>
      </thead>
      <tbody>
        {["<20", "20-30", "31-50", ">50"].map((ageGroup) => (
          <tr key={ageGroup}>
            <td className="border border-gray-300 p-2">{ageGroup} years</td>
            {categories.flatMap((category) => [
              <td key={`${category}-recruits`} className="border border-gray-300 p-2">{data?.[category]?.[ageGroup]?.recruits || 0}</td>,
              <td key={`${category}-left`} className="border border-gray-300 p-2">{data?.[category]?.[ageGroup]?.left || 0}</td>,
              <td key={`${category}-total`} className="border border-gray-300 p-2">{data?.[category]?.[ageGroup]?.total || 0}</td>
            ])}
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">{data.financialYear || "N/A"}</h2>
      {renderTableSection(["male", "female"])}
      {renderTableSection(["other", "notDisclosed"])}
    </div>
  );
};

export default EmployeeDataDisplay;

