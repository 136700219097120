import React from 'react'

function Paternity() {
  return (
    <div>
      Paternity
    </div>
  )
}

export default Paternity
