import React from 'react'

function HiringAndTurnOver() {
  return (
    <div>
      Hiring and Turnover
    </div>
  )
}

export default HiringAndTurnOver
