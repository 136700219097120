

import React, { useEffect, useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import HiringAndTurnoverForm from './HiringAndTurnoverForm';
import EmployeeDataDisplay from '../DataView/HiringAndTurnover';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSiteData, postSocialHiringTurnoverInputs } from '../../../features/social/socialThunk';
import useApi from "../../../customHooks/useApi"
const HiringAndTurnOver = () => {
  const {employementType,siteData}=useSelector(state=>state.social)
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
const dispatch=useDispatch()
  const api=useApi()
  function handleSubmitData(data) {
    setFormData(data) 
    dispatch(postSocialHiringTurnoverInputs({api,formData:data,employementType}))
  
  }

  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <HiringAndTurnoverForm handleSubmitData={handleSubmitData} siteOptions={siteData}/>;
      case "display":
        return <EmployeeDataDisplay data={formData} siteOptions={siteData}/>;
      default:
        return null;
    }
  };
  useEffect(()=>{
  dispatch(fetchSiteData({api}))
  },[])
  return (
    <div className='h-full w-full overflow-auto p-2 px-6 py-3'>
        {renderTab()}
    </div>
  );
};

export default HiringAndTurnOver;

