import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "../../../components/common/Table/TablePagination";
import {
  resetAdminDashboardData,
  setadminDashboardForm,
  setCurrentPage,
  setItemsPerPage,
} from "../Redux/SettingSlices";
import { ReactComponent as PencilIcon } from "../../../app/assets/PencilIcon.svg";
import { ReactComponent as UserIconPlus } from "../../../app/assets/UserIconPlus.svg";
import { ReactComponent as DeleteIcon } from "../../../app/assets/DeleteIcon.svg";
import { ReactComponent as SendIcon } from "../../../app/assets/SendIcon.svg";
import Table from "../../../components/common/Table/Table";
import DashboardCard from "../components/DashboardCard";
import Popup from "./components/PopUp";
import ConfirmPopup from "./components/ConfirmPopUp";
import EmailPopup from "./components/EmailPopUp";
import {
  deleteMember,
  fetchAdminDashboardData,
  fetchModule,
  fetchRole,
  fetchSubModule,
} from "../Redux/SettingThunk";
import useApi from "../../../customHooks/useApi";
import { toast } from "react-toastify";
import { forgotPassword } from "../../../features/auth/authThunks";

const AdminDashboard = () => {
  const { adminDashboardData, roles, itemsPerPage, totalPages, currentPage,Allroles } =
    useSelector((state) => state.setting);
  const api = useApi();
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [isLinkSendPopupOpen, setIsLinkSendPopupOpen] = useState(false);
  const [member_id, setmember_id] = useState("");
  const [username, setusername] = useState("");
  const [dataRows, setDataRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false); // State to manage EmailPopup visibility
  const [actionType, setactionType] = useState("newEntry");
  const [selectedRole, setSelectedRole] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
    dispatch(resetAdminDashboardData());
  };

  const openEmailPopup = () => {
    setIsEmailPopupOpen(true);
  };

  const closeEmailPopup = () => {
    setIsEmailPopupOpen(false);
  };

  const handleNextFromEmailPopup = (email) => {
    dispatch(setadminDashboardForm({ ...email }));
    closeEmailPopup();
    setactionType("newEntry");

    openPopup();
  };

  const handleCloseDeletePopup = () => {
    setIsDeletePopupOpen(false);
  };

  const handleConfirmPopup = () => {
    if (member_id) {
      dispatch(deleteMember({ api, id: member_id }));
    } else {
      toast.error("member id not define");
    }
    setIsDeletePopupOpen(false);
  };

  const handleCloseSendLinkPopup = () => {
    setIsLinkSendPopupOpen(false);
  };

  const handleConfirmSendLinkPopup = async () => {
    try {
      // Dispatch the forgot password action
      const res = await dispatch(forgotPassword({ username }));
      if (res?.payload?.success) {
        setIsLinkSendPopupOpen(false);
      }
    } catch (error) {
      // Handle any unexpected errors
      toast.error("An error occurred. Please try again.");
    }
  };

  const dispatch = useDispatch();
  const headingsToDataKeyMap = {
    "USER NAME": "user_name",
    ROLES: "role_name",
    // "ROLES": "role_alias",
    PERMISSIONS: "permissions",
    "RESET PASSWORD": "reset_password",
    DELETE: "delete",
  };

  const [paginationConfig, setPaginationConfig] = useState({
    paginationEnabled: true,
    currentPage: 1,
    totalPage: 1,
    itemsPerPage: 10,
    handleItemsPerPage: null,
    handlePageChange: null,
  });

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handlePermissions = (data, type) => {
    
    openPopup();

    if (data && type === "update") {
      setactionType(type);
      dispatch(
        setadminDashboardForm({
          user_id: data.id,
          role_id: data.role_id || "",
          role_type_id: data.role_type_id,
          permissions: data.permissions,
          role_alias: data.role_alias,
        })
      );
      
    } else {
      setactionType("newEntry");
      dispatch(resetAdminDashboardData());
    }
  };

  const handleResetPassword = (row) => {
    setIsLinkSendPopupOpen(true);
    setusername(row.user_name);
  };

  const handleDelete = (row) => {
    setmember_id(row.id);
    setIsDeletePopupOpen(true);
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "reset_password":
        cellHtml = (
          <span className="w-full flex justify-center">
            <SendIcon
              className="cursor-pointer"
              onClick={() => handleResetPassword(row)}
            />
          </span>
        );
        break;
      case "user":
        cellHtml = (
          <div className=" flex float-start w-full space-x-2 items-center text-center">
            <img
              className="rounded-full flex flex-shrink-0 w-8 h-8 object-cover border shadow-lg"
              src={row.img}
              alt="user"
            />
            <span className="text-black">{row[k]}</span>
          </div>
        );
        break;
      case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
        );
        break;
      case "permissions":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handlePermissions(row, "update")}
            />
          </span>
        );
        break;
      default:
        cellHtml = <span className="w-full flex justify-center">{row[k]}</span>;
        break;
    }
    return cellHtml;
  };

  useEffect(() => {
    let transformedDataRows = filteredData.map((row) => {
      let transformedDataRow = [];
      Object.values(headingsToDataKeyMap).forEach((k) => {
        if (k.toString().trim() !== "") {
          transformedDataRow.push(generateCellHtml(row, k));
        }
      });
      return transformedDataRow;
    });

    setDataRows(transformedDataRows);
    setPaginationConfig({
      ...paginationConfig,
      currentPage: currentPage,
      totalPages: totalPages,
      itemsPerPage: itemsPerPage,
      handleItemsPerPage: handleItemsPerPage,
      handlePageChange: handlePageChange,
    });
  }, [filteredData, selectedRole]);

  const handleClick = (text) => {
    // setTitle(text);
  };
  const handleRoleFilterChange = (event) => {
    setSelectedRole(event.target.value);
  };

  // Filter the adminDashboardData based on selectedRole
  useEffect(() => {
    const data =
      selectedRole === "all"
        ? adminDashboardData
        : adminDashboardData.filter(
            (item) => Number(item.role_type_id) === Number(selectedRole)
          );

    setFilteredData(data);
  }, [selectedRole, adminDashboardData]);
  useEffect(() => {
    dispatch(fetchAdminDashboardData({ api }));
    dispatch(fetchRole({ api }));
    dispatch(fetchModule({ api }));
    dispatch(fetchSubModule({ api }));
  }, []);
// console.log(Allroles)
  return (
    <div className="p-4 relative">
      <div className="flex space-x-6">
        <DashboardCard
          handleClick={handleClick}
          text="Number of Active Users"
          avatars={adminDashboardData}
        />
        <DashboardCard
          handleClick={handleClick}
          text="Data In"
          avatars={adminDashboardData.filter(
            (i) => i?.permissions?.data_entry?.is_enabled
          )}
        />
        <DashboardCard
          handleClick={handleClick}
          text="Analyze"
          avatars={adminDashboardData.filter(
            (i) => i?.permissions?.visualization
            ?.is_enabled
          )}
        />
        <DashboardCard
          handleClick={handleClick}
          text="Reports"
          avatars={adminDashboardData.filter(
            (i) => i?.permissions?.reporting
            ?.is_enabled
          )}
        />
        {/* <DashboardCard handleClick={handleClick} text="Audits" avatars={adminDashboardData} /> */}
      </div>
      <div className="flex justify-start space-x-2 items-center p-1 px-2 bg-white rounded-lg w-fit border-2 mt-3">
        <label htmlFor="role">Filter Role</label>
        <select
          name="role"
          id="role"
          className="outline-none border-l-2 p-1"
          onChange={handleRoleFilterChange}
        >
          <option value="all">All Roles</option>
          {roles &&
            roles.map((i) => (
              <option key={i.id} value={i.id}>
                {i.role_name}
              </option>
            ))}
        </select>
      </div>

      <div className="mt-2">
        <Table
          headings={Object.keys(headingsToDataKeyMap)}
          dataRows={dataRows}
          paginationObject={paginationConfig}
          height="50vh"
          dashboard={false}
        />
        {paginationConfig &&
          paginationConfig?.paginationEnabled &&
          paginationConfig?.handleItemsPerPage &&
          typeof paginationConfig?.handleItemsPerPage === "function" &&
          paginationConfig?.handlePageChange &&
          typeof paginationConfig?.handlePageChange === "function" && (
            <TablePagination paginationObject={paginationConfig} />
          )}
      </div>
      <button
        onClick={openEmailPopup}
        className="flex absolute top-[59px] right-10 justify-between items-center p-1 px-3 rounded-lg text-green-600 bg-[#C1EBDB]"
      >
        Add New User{" "}
        <span>
          <UserIconPlus />
        </span>
      </button>
      {isEmailPopupOpen && (
        <EmailPopup
          isOpen={isEmailPopupOpen}
          onClose={closeEmailPopup}
          onNext={handleNextFromEmailPopup}
        />
      )}
      {isOpen && (
        <Popup
          onClose={closePopup}
          data={adminDashboardData}
          actionType={actionType}
        />
      )}

      {isDeletePopupOpen && (
        <ConfirmPopup
          mainHeading="Are you sure ?"
          subHeading="save the changes "
          buttonText1="Cancel"
          buttonText2="Delete"
          onClose={handleCloseDeletePopup}
          onConfirm={handleConfirmPopup}
        />
      )}
      {isLinkSendPopupOpen && (
        <ConfirmPopup
          mainHeading="Are you sure ?"
          subHeading="send the Link "
          buttonText1="Cancel"
          buttonText2="Send"
          onClose={handleCloseSendLinkPopup}
          onConfirm={handleConfirmSendLinkPopup}
        />
      )}
    </div>
  );
};

export default AdminDashboard;
