// import React, { useState } from 'react';

// const TrainingMetricsForm = () => {
//   const initialData = {
//     financialYear: '',
//     trainingMetrics: {
//       male: {
//         healthAndSafety: 0,
//         skillUpgradation: 0,
//       },
//       female: {
//         healthAndSafety: 0,
//         skillUpgradation: 0,
//       },
//       other: {
//         healthAndSafety: 0,
//         skillUpgradation: 0,
//       },
//       notDisclosed: {
//         healthAndSafety: 0,
//         skillUpgradation: 0,
//       },
//     },
//   };

//   const [formData, setFormData] = useState(initialData);

//   const handleFinancialYearChange = (e) => {
//     setFormData({ ...formData, financialYear: e.target.value });
//   };

//   const handleInputChange = (category, field, value) => {
//     setFormData({
//       ...formData,
//       trainingMetrics: {
//         ...formData.trainingMetrics,
//         [category]: {
//           ...formData.trainingMetrics[category],
//           [field]: Number(value),
//         },
//       },
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted: ', formData);
//     // Logic to send form data to API or further processing.
//   };

//   return (
//     <div className="w-full p-2 px-6 h-full">
      
//       <form onSubmit={handleSubmit}>
//         {/* Financial Year Input */}
//         <div className="mb-6">
//           <label className="block text-gray-700">Financial Year</label>
//           <select
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.financialYear}
//             onChange={handleFinancialYearChange}
//           >
//             <option value="">Select financial year</option>
//             <option value="2024">2024</option>
           
//           </select>
//         </div>

//         {/* Training Metrics Section */}
//         <div className="mb-6">
         
//           <div className="grid grid-cols-5 gap-4 font-semibold text-gray-700 mt-6">
//             <div>Metric</div>
//             <div>Male</div>
//             <div>Female</div>
//             <div>Other</div>
//             <div>Not Disclosed</div>
//           </div>

//           {/* Rows for each metric */}
//           {[
//             { field: 'healthAndSafety', label: 'Trainings on Health and Safety Measures' },
//             { field: 'skillUpgradation', label: 'Trainings on Skill Upgradation' },
//           ].map((metric, idx) => (
//             <div className="grid grid-cols-5 gap-4 mt-4" key={idx}>
//               <div className="text-gray-700 font-bold">{metric.label}</div>
//               {['male', 'female', 'other', 'notDisclosed'].map((category) => (
//                 <input
//                   key={category}
//                   type="number"
//                   className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//                   value={formData.trainingMetrics[category][metric.field]}
//                   onChange={(e) =>
//                     handleInputChange(category, metric.field, e.target.value)
//                   }
//                 />
//               ))}
//             </div>
//           ))}
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default TrainingMetricsForm;
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialTrainingInputs } from '../../../features/social/socialThunk';

const TrainingMetricsForm = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const config = {
    // siteOptions: ['Site A', 'Site B', 'Site C'], // Example site options
    financialYearOptions: ['2023', '2024'], // Example financial year options
    sections: [
      {
        key: 'trainingMetrics',
        label: 'Training Metrics',
        rows: [
          {
            key: 'healthSafetyTrainings',
            label: 'Trainings on Health and Safety Measures',
          },
          {
            key: 'skillUpgradationTrainings',
            label: 'Trainings on Skill Upgradation',
          },
        ],
        fields: [
        
          {
            key: 'male',
            label: 'Male',
            type: 'number', 
           
          },
          {
            key: 'female',
            label: 'Female',
            type: 'number',
         
          },
          {
            key: 'other',
            label: 'Other',
            type: 'number',
          
          },
          {
            key: 'notDisclosed',
            label: 'Not Disclosed',
            type: 'number',
           
          },
         
        ],
      },
    ],
  };

  
  function handleSubmitData(data) {
    dispatch(postSocialTrainingInputs({api,formData:data,employementType}))
    setFormData(data) 
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default TrainingMetricsForm;
