import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';

const RetirementBenefitsForm = ({handleSubmitData}) => {
  const loading = useSelector((state) => state.common.isLoading);
  
  const initialData = {
    financialYear: '',
    benefits: {
      pf: { employeesCovered: '', status: '' },
      gratuity: { employeesCovered: '', status: '' },
      esi: { employeesCovered: '', status: '' },
    },
  };

  const [formData, setFormData] = useState(initialData);

  const handleFinancialYearChange = (e) => {
    setFormData({ ...formData, financialYear: e.target.value });
  };

  const handleEmployeeCoveredChange = (benefitType, value) => {
    setFormData({
      ...formData,
      benefits: {
        ...formData.benefits,
        [benefitType]: {
          ...formData.benefits[benefitType],
          employeesCovered: Number(value),
        },
      },
    });
  };

  const handleStatusChange = (benefitType, value) => {
    setFormData({
      ...formData,
      benefits: {
        ...formData.benefits,
        [benefitType]: {
          ...formData.benefits[benefitType],
          status: value,
        },
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    handleSubmitData(formData);
  };

  return (
    <div className="w-full p-2 px-6 h-full">
      
      <form onSubmit={handleSubmit}>
        {/* Financial Year Input */}
        <div className="mb-6">
          <label className="block text-gray-700">Financial Year</label>
          <select
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
            value={formData.financialYear}
            onChange={handleFinancialYearChange}
          >
            <option value="">Select financial year</option>
            <option value="2024">2024</option>
           
          </select>
        </div>

        {/* Benefits Section */}
        <div className="mb-6">
          
          <div className="grid grid-cols-3 gap-4 font-semibold text-gray-700 mt-6">
            <div>Benefit Type</div>
            <div>Number of Employees Covered</div>
            <div>Deducted and Deposited with Authority</div>
          </div>

          {/* PF */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="text-gray-700">PF</div>
            <input
              type="number"
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.pf.employeesCovered}
              onChange={(e) => handleEmployeeCoveredChange('pf', e.target.value)}
            />
            <select
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.pf.status}
              onChange={(e) => handleStatusChange('pf', e.target.value)}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="na">NA</option>
            </select>
          </div>

          {/* Gratuity */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="text-gray-700">Gratuity</div>
            <input
              type="number"
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.gratuity.employeesCovered}
              onChange={(e) => handleEmployeeCoveredChange('gratuity', e.target.value)}
            />
            <select
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.gratuity.status}
              onChange={(e) => handleStatusChange('gratuity', e.target.value)}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {/* ESI */}
          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="text-gray-700">ESI</div>
            <input
              type="number"
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.esi.employeesCovered}
              onChange={(e) => handleEmployeeCoveredChange('esi', e.target.value)}
            />
            <select
              className="bg-gray-200 text-neutral-700 text-xs py-1.5 px-1 border rounded w-full"
              value={formData.benefits.esi.status}
              onChange={(e) => handleStatusChange('esi', e.target.value)}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
              <option value="na">NA</option>
            </select>
          </div>
        </div>

        {/* Submit Button */}
        <div className="px-4 w-full py-3 flex gap-x-6 gap-y-4 items-center justify-center">
        <button
          type="submit"
          disabled={loading}
          className={`py-2 px-6 text-white text-sm ${
            loading ? 'bg-green-500 cursor-wait' : 'bg-green-600'
          } rounded-md`}
        >
          Save and Continue
        </button>
      </div>
      </form>
    </div>
  );
};

export default RetirementBenefitsForm;


// import React from 'react';
// import GenericForm from './GenericForm';

// const RetirementBenefitsForm = () => {
//    const formConfig = {
//     siteOptions: ['Site 1', 'Site 2'], // Example site options
//     financialYearOptions: ['2022', '2023', '2024'], // Example financial year options
//     sections: [
//       {
//         key: 'benefits', // Unique key for this section
//         label: 'Employee Benefits', // Section label
//         rows: [
//           { key: 'pf', label: 'PF' }, // Row for PF
//           { key: 'gratuity', label: 'Gratuity' }, // Row for Gratuity
//           { key: 'esi', label: 'ESI' }, // Row for ESI
//         ],
//         fields: [
//           { 
//             key: 'numberOfEmployees', 
//             label: 'Number of Employees covered', 
//             type: 'number' 
//           },
//           { 
//             key: 'deposited', 
//             label: 'Deducted and deposited with the authority (PF)', 
//             type: 'select', 
//             options: ['Yes', 'No'], // Options specific for PF
//             rowKey: 'pf', // Specify this field is for PF
//           },
//           { 
//             key: 'deposited', 
//             label: 'Deducted and deposited with the authority (Gratuity)', 
//             type: 'select', 
//             options: ['Yes', 'No'], // Options specific for Gratuity
//             rowKey: 'gratuity', // Specify this field is for Gratuity
//           },
//           { 
//             key: 'deposited', 
//             label: 'Deducted and deposited with the authority (ESI)', 
//             type: 'select', 
//             options: ['Yes', 'No', 'NA'], // Options specific for ESI
//             rowKey: 'esi', // Specify this field is for ESI
//           },
//         ],
//       },
//     ],
//   };
  

//   return (
//     <div className='h-full w-full p-2 px-6'>
//       <GenericForm config={formConfig} />
//     </div>
//   );
// };

// export default RetirementBenefitsForm;
