import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';

const OHSForm  = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const config = {
    siteOptions: ["Site 1", "Site 2", "Site 3"], // Options for site dropdown
    financialYearOptions: ["2024", "2023", "2022"], // Options for financial year dropdown
    sections: [
      {
        key: "ohsCoverage",
        label: "OHS System Coverage for Non-Employees",
        rows: [
          {
            key: "employeesCoveredBySystem",
            label: "Number of Employees covered by System",
          },
          {
            key: "internallyAuditedEmployees",
            label: "Number of Employees covered by System that have been internally audited",
          },
          {
            key: "externallyAuditedEmployees",
            label:
              "Number of Employees covered by System who are covered by such a system that has been audited or certified by an external party",
          },
        ],
        fields: [
          {
            key: "count",
            label: "Count",
            type: "number", // Input type
            placeholder: "Enter count",
          },
        ],
      },
    ],
  };

  
  function handleSubmitData(data) {
    setFormData(data) 
    setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config

        } handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config

        } data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default OHSForm;