import React from 'react'

function EmployeeRehabilitationPage() {
  return (
    <div>
      EmployeeRehabilitationPage
    </div>
  )
}

export default EmployeeRehabilitationPage
