// import React, { useState } from 'react';

// const UnionAssociationParticipationForm = () => {
//   const initialData = {
//     financialYear: '',
//     unionParticipation: {
//       male: { employees: 0, workers: 0 },
//       female: { employees: 0, workers: 0 },
//       other: { employees: 0, workers: 0 },
//       notDisclosed: { employees: 0, workers: 0 },
//     },
//   };

//   const [formData, setFormData] = useState(initialData);

//   const handleFinancialYearChange = (e) => {
//     setFormData({ ...formData, financialYear: e.target.value });
//   };

//   const handleInputChange = (category, field, value) => {
//     setFormData({
//       ...formData,
//       unionParticipation: {
//         ...formData.unionParticipation,
//         [category]: {
//           ...formData.unionParticipation[category],
//           [field]: Number(value),
//         },
//       },
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted: ', formData);
//     // Logic to send form data to API or further processing.
//   };

//   return (
//     <div className="w-full p-2 px-6 h-full">
//       <form onSubmit={handleSubmit}>
//         {/* Financial Year Input */}
//         <div className="mb-6">
//           <label className="block text-gray-700">Financial Year</label>
//           <select
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.financialYear}
//             onChange={handleFinancialYearChange}
//           >
//             <option value="">Select financial year</option>
//             <option value="2024">2024</option>
           
//           </select>
//         </div>

//         {/* Union Participation Section */}
//         <div className="mb-6">
         
//           <div className="grid grid-cols-5 gap-4 font-semibold text-gray-700 mt-6">
//             <div>Accounting Metric</div>
//             <div>Male</div>
//             <div>Female</div>
//             <div>Other</div>
//             <div>Not Disclosed</div>
//           </div>

//           {/* Employees in Union */}
//           <div className="grid grid-cols-5 gap-4 mt-4">
//             <div className="text-gray-700 font-bold">Number of employees who are part of association(s) or Union</div>
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.male.employees}
//               onChange={(e) => handleInputChange('male', 'employees', e.target.value)}
//             />
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.female.employees}
//               onChange={(e) => handleInputChange('female', 'employees', e.target.value)}
//             />
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.other.employees}
//               onChange={(e) => handleInputChange('other', 'employees', e.target.value)}
//             />
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.notDisclosed.employees}
//               onChange={(e) => handleInputChange('notDisclosed', 'employees', e.target.value)}
//             />
//           </div>

//           {/* Workers in Union */}
//           <div className="grid grid-cols-5 gap-4 mt-4">
//             <div className="text-gray-700 font-bold">Number of workers who are part of association(s) or Union</div>
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.male.workers}
//               onChange={(e) => handleInputChange('male', 'workers', e.target.value)}
//             />
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.female.workers}
//               onChange={(e) => handleInputChange('female', 'workers', e.target.value)}
//             />
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.other.workers}
//               onChange={(e) => handleInputChange('other', 'workers', e.target.value)}
//             />
//             <input
//               type="number"
//               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.unionParticipation.notDisclosed.workers}
//               onChange={(e) => handleInputChange('notDisclosed', 'workers', e.target.value)}
//             />
//           </div>
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-1 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default UnionAssociationParticipationForm;

import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialUnionAssociationParticipationInputs } from '../../../features/social/socialThunk';

const EmployeeRehabilitationPage = () => {
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const config = {
    // siteOptions: ['Site A', 'Site B', 'Site C'], // Example site options
    financialYearOptions: ['2023', '2024'], // Example financial year options
    sections: [
      {
        key: 'accountingMetrics',
        label: 'Accounting Metrics',
        rows: [
          {
            key: 'associationUnionEmployees',
            label: 'Number of employees who are part of association(s) or Union',
          },
          {
            key: 'associationUnionWorkers',
            label: 'Number of Workers who are part of association(s) or Union',
          },
        ],
        fields: [
          {
            key: 'male',
            label: 'Male',
            type: 'number',
           
          },
          {
            key: 'female',
            label: 'Female',
            type: 'number',
           
          },
          {
            key: 'other',
            label: 'Other',
            type: 'number',
        
          },
          {
            key: 'notDisclosed',
            label: 'Not Disclosed',
            type: 'number',
          
          }
        
        ],
      },
    ],
  };
  
 
  
  function handleSubmitData(data) {
    dispatch(postSocialUnionAssociationParticipationInputs({api,formData:data,employementType}))
    setFormData(data) 
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeRehabilitationPage;
