// import React, { useState } from 'react';

// const EmployeeWellBeingForm = () => {
//   const initialData = {
//     financialYear: '',
//     wellBeingMetrics: {
//       male: {
//         lifeInsurance: 0,
//         disabilityCoverage: 0,
//         stockOwnership: 0,
//         healthInsurance: 0,
//         accidentInsurance: 0,
//         maternityBenefits: 0,
//         paternityBenefits: 0,
//         dayCareFacilities: 0,
//       },
//       female: {
//         lifeInsurance: 0,
//         disabilityCoverage: 0,
//         stockOwnership: 0,
//         healthInsurance: 0,
//         accidentInsurance: 0,
//         maternityBenefits: 0,
//         paternityBenefits: 0,
//         dayCareFacilities: 0,
//       },
//       other: {
//         lifeInsurance: 0,
//         disabilityCoverage: 0,
//         stockOwnership: 0,
//         healthInsurance: 0,
//         accidentInsurance: 0,
//         maternityBenefits: 0,
//         paternityBenefits: 0,
//         dayCareFacilities: 0,
//       },
//       notDisclosed: {
//         lifeInsurance: 0,
//         disabilityCoverage: 0,
//         stockOwnership: 0,
//         healthInsurance: 0,
//         accidentInsurance: 0,
//         maternityBenefits: 0,
//         paternityBenefits: 0,
//         dayCareFacilities: 0,
//       },
//     },
//   };

//   const [formData, setFormData] = useState(initialData);

//   const handleFinancialYearChange = (e) => {
//     setFormData({ ...formData, financialYear: e.target.value });
//   };

//   const handleInputChange = (category, field, value) => {
//     setFormData({
//       ...formData,
//       wellBeingMetrics: {
//         ...formData.wellBeingMetrics,
//         [category]: {
//           ...formData.wellBeingMetrics[category],
//           [field]: Number(value),
//         },
//       },
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted: ', formData);
//     // Logic to send form data to API or further processing.
//   };

//   return (
//     <div className="w-full p-2 px-6 overflow-auto">
    
//       <form onSubmit={handleSubmit}>
//         {/* Financial Year Input */}
//         <div className="mb-6">
//           <label className="block text-gray-700">Financial Year</label>
//           <select
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.financialYear}
//             onChange={handleFinancialYearChange}
//           >
//             <option value="">Select financial year</option>
//             <option value="2024">2024</option>
           
//           </select>
//         </div>

//         {/* Well-Being Metrics Section */}
//         <div className="mb-6">
        
//           <div className="grid grid-cols-5 gap-4 font-semibold text-gray-700 mt-6">
//             <div>Metric</div>
//             <div>Male</div>
//             <div>Female</div>
//             <div>Other</div>
//             <div>Not Disclosed</div>
//           </div>

//           {/* Rows for each metric */}
//           {[
//             'lifeInsurance',
//             'disabilityCoverage',
//             'stockOwnership',
//             'healthInsurance',
//             'accidentInsurance',
//             'maternityBenefits',
//             'paternityBenefits',
//             'dayCareFacilities',
//           ].map((metric, idx) => (
//             <div className="grid grid-cols-5 gap-4 mt-4" key={idx}>
//               <div className="text-gray-700 font-bold">
//               Employees covered by {metric
//                   .replace(/([A-Z])/g, ' $1')
//                   .replace(/^./, (str) => str.toUpperCase())}
//               </div>
//               {['male', 'female', 'other', 'notDisclosed'].map((category) => (
//                 <input
//                   key={category}
//                   type="number"
//                   className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//                   value={formData.wellBeingMetrics[category][metric]}
//                   onChange={(e) => handleInputChange(category, metric, e.target.value)}
//                 />
//               ))}
//             </div>
//           ))}
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-1 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EmployeeWellBeingForm;


import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import useApi from '../../../customHooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialEmpWellBeingInputs } from '../../../features/social/socialThunk';

const EmployeeWellBeingForm = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const employeeWellBeingConfig = {
    title: 'Employee Well-being Form',
    financialYearOptions: ['2022', '2023', '2024'], // Example years, you can modify them as needed
    siteOptions: siteData, // Add site options here if needed
    sections: [
      {
        key: 'wellBeingMetrics',
        label: 'Employee Well-being Metrics',
        rows: [
          { key: 'life_insurance', label: 'Employees covered by Life Insurance' },
          { key: 'disability_coverage', label: 'Employees covered by Disability and Invalidity Coverage' },
          { key: 'stock_ownership', label: 'Employees covered by Stock Ownership' },
          { key: 'health_insurance', label: 'Employees covered by Health Insurance' },
          { key: 'accident_insurance', label: 'Employees covered by Accident Insurance' },
          { key: 'maternity_benefits', label: 'Employees covered by Maternity Benefits' },
          { key: 'paternity_benefits', label: 'Employees covered by Paternity Benefits' },
          { key: 'day_care_facilities', label: 'Employees covered by Day Care Facilities' },
        ],
        fields: [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not_disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  
  function handleSubmitData(data) {
 
    setFormData(data) 
    dispatch(postSocialEmpWellBeingInputs({api,formData:{...data,employement_category:employementType}}))
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={employeeWellBeingConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={employeeWellBeingConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeWellBeingForm;

