// import React, { useState } from 'react';

// const WorkplaceSafetyMetricsForm = () => {
//   const initialData = {
//     financialYear: '',
//     safetyMetrics: {
//       male: {
//         LTIFR: 0,
//         injuries: 0,
//         fatalities: 0,
//         highConsequenceInjuries: 0,
//       },
//       female: {
//         LTIFR: 0,
//         injuries: 0,
//         fatalities: 0,
//         highConsequenceInjuries: 0,
//       },
//       other: {
//         LTIFR: 0,
//         injuries: 0,
//         fatalities: 0,
//         highConsequenceInjuries: 0,
//       },
//       notDisclosed: {
//         LTIFR: 0,
//         injuries: 0,
//         fatalities: 0,
//         highConsequenceInjuries: 0,
//       },
//     },
//   };

//   const [formData, setFormData] = useState(initialData);

//   const handleFinancialYearChange = (e) => {
//     setFormData({ ...formData, financialYear: e.target.value });
//   };

//   const handleInputChange = (category, field, value) => {
//     setFormData({
//       ...formData,
//       safetyMetrics: {
//         ...formData.safetyMetrics,
//         [category]: {
//           ...formData.safetyMetrics[category],
//           [field]: Number(value),
//         },
//       },
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted: ', formData);
//     // Logic to send form data to API or further processing.
//   };

//   return (
//     <div className="w-full p-2 px-6 h-full">
//       <form onSubmit={handleSubmit}>
//         {/* Financial Year Input */}
//         <div className="mb-6">
//           <label className="block text-gray-700">Financial Year</label>
//           <select
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.financialYear}
//             onChange={handleFinancialYearChange}
//           >
//             <option value="">Select financial year</option>
//             <option value="2024">2024</option>
           
//           </select>
//         </div>

//         {/* Safety Metrics Section */}
//         <div className="mb-6">
//           <h3 className="text-xl font-medium">Workplace Safety Metrics</h3>
//           <div className="grid grid-cols-5 gap-4 font-semibold text-gray-700 mt-6">
//             <div>Metric</div>
//             <div>Male</div>
//             <div>Female</div>
//             <div>Other</div>
//             <div>Not Disclosed</div>
//           </div>

//           {/* Rows for each metric */}
//           {[
//             { field: 'LTIFR', label: 'Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)' },
//             { field: 'injuries', label: 'Total Recordable Work-Related Injuries' },
//             { field: 'fatalities', label: 'Number of Fatalities' },
//             { field: 'highConsequenceInjuries', label: 'High Consequence Work-Related Injury or Ill-Health (excluding fatalities)' },
//           ].map((metric, idx) => (
//             <div className="grid grid-cols-5 gap-4 mt-4" key={idx}>
//               <div className="text-gray-700 font-bold">{metric.label}</div>
//               {['male', 'female', 'other', 'notDisclosed'].map((category) => (
//                 <input
//                   key={category}
//                   type="number"
//                   className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
//                   value={formData.safetyMetrics[category][metric.field]}
//                   onChange={(e) =>
//                     handleInputChange(category, metric.field, e.target.value)
//                   }
//                 />
//               ))}
//             </div>
//           ))}
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default WorkplaceSafetyMetricsForm;
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import useApi from '../../../customHooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialSafetyRelatedIncidentsInputs } from '../../../features/social/socialThunk';

const WorkplaceSafetyMetricsForm = () => {
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const config = {
    // siteOptions: ['Site A', 'Site B', 'Site C'], // Example site options
    financialYearOptions: ['2023', '2024'], // Example financial year options
    sections: [
      {
        key: 'workplaceMetrics',
        label: 'Workplace Metrics',
        rows: [
          {
            key: 'fatalities',
            label: 'Number of Fatalities',
          },
          {
            key: 'workRelatedInjuries',
            label: 'Total Recordable Work-related Injuries',
          },
          {
            key: 'highConsequenceInjuries',
            label: 'High Consequence Work-related Injury or Ill-health (Excluding Fatalities)',
          },
          {
            key: 'ltifr',
            label: 'Lost Time Injury Frequency Rate (LTIFR) (per one million-person hours worked)',
          },
        ],
        fields: [
          // Fields for Number of Fatalities
          {
            key: 'male',
            label: 'Male',
            type: 'number', // Numeric input for counts
         
          },
          {
            key: 'female',
            label: 'Female',
            type: 'number',
         
          },
          {
            key: 'other',
            label: 'Other',
            type: 'number',
           
          },
          {
            key: 'notDisclosed',
            label: 'Not Disclosed',
            type: 'number',
          
          },
  
         
         
        ],
      },
    ],
  };
  

  
  function handleSubmitData(data) {
 dispatch(postSocialSafetyRelatedIncidentsInputs({api,formData:data,employementType}) )
    setFormData(data) 
   
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={config} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={config} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default WorkplaceSafetyMetricsForm;
