export function filterSpecificationsByVehicleType(
  data,
  logisticMode,
  vehicleTypeId
) {
  const filteredSpecifications = [];

  data.forEach((mode) => {
    if (mode.logistic_mode === logisticMode) {
    
      const vehicleTypeName = mode.vehicle_types.find(
        (vehicleType) => vehicleType.id == vehicleTypeId
      )?.type;

      if (vehicleTypeName) {
        
        mode.specifications.forEach((spec) => {
          if (spec.vehicle_type === vehicleTypeName) {
            filteredSpecifications.push(spec);
          }
        });
      }
    }
  });

  return filteredSpecifications;
}
export function filtertravelSubTypeType(data, mode_of_travel, travel_type_id) {
  const filteredSpecifications = [];
  
  data.forEach((mode) => {
    
    if (mode.mode_of_travel === mode_of_travel) {
      
      const travelTypeName = mode?.travel_type?.find(
        (travelType) => travelType.id == travel_type_id
        )?.travel_type;
      if (travelTypeName) {
     
        mode.travel_subtype.forEach((spec) => {
       
          if (spec.travel_type === travelTypeName) {
            filteredSpecifications.push(spec);
          }
        });
      }
    }
  });

  return filteredSpecifications;
}
