export function aggregateDataByEmploymentType(data, employmentType) {
    // Filter data based on the provided employment type
    const filteredData = data.filter(item => item.employee_type === employmentType);
  
    // Initialize an empty result object
    const aggregatedData = {};
  
    // Aggregate data by financial_year, employee_type, and gender
    filteredData.forEach(item => {
      const { financial_year, employement_category, gender, no_of_employees,site } = item;
  
      // Initialize the first level (financial year)
      if (!aggregatedData[financial_year]) {
        aggregatedData[financial_year] = {};
      }
      
      // Initialize the second level (employee type)
      if (!aggregatedData[financial_year][employement_category]) {
        aggregatedData[financial_year][employement_category] = {};
        if (!aggregatedData[site]&&site) {
          aggregatedData[financial_year][employement_category].site= site;
        }
      }
  
      // Initialize the third level (gender)
      if (!aggregatedData[financial_year][employement_category][gender]) {
        aggregatedData[financial_year][employement_category][gender] = 0;
      }
  
      // Sum up the number of employees
      aggregatedData[financial_year][employement_category][gender] += parseInt(no_of_employees, 10);
    });
  
    return aggregatedData;
  }
  export function transformApiData(data,employmentType) {
    const filteredData = data.filter(item => item.employement_category === employmentType);
  
    const structuredData={}
    filteredData.forEach(entry => {
    const { financial_year, gender } = entry;

    // Set up base structure if financial year doesn't exist
    if (!structuredData[financial_year]) {
      structuredData[financial_year] = {
        // financialYear: financial_year,
        life_insurance: { male: "", female: "", other: "", not_disclosed: "" },
        disability_coverage: { male: "", female: "", other: "", not_disclosed: "" },
        stock_ownership: { male: "", female: "", other: "", not_disclosed: "" },
        health_insurance: { male: "", female: "", other: "", not_disclosed: "" },
        accident_insurance: { male: "", female: "", other: "", not_disclosed: "" },
        maternity_benefits: { male: "", female: "", other: "", not_disclosed: "" },
        paternity_benefits: { male: "", female: "", other: "", not_disclosed: "" },
        day_care_facilities: { male: "", female: "", other: "", not_disclosed: "" }
      };
    }

    // Map coverage fields to the structure based on gender
    const coverageTypes = [
      "life_insurance",
      "disability_coverage",
      "stock_ownership",
      "health_insurance",
      "accident_insurance",
      "maternity_benefits",
      "paternity_benefits",
      "day_care_facilities"
    ];

    coverageTypes.forEach(type => {
      const coverageKey = `${type}_coverage`; // Form key dynamically (e.g., "life_insurance_coverage")
      structuredData[financial_year][type][gender] = entry[coverageKey];
    });
  });

  return structuredData;
}


  // Function to populate data for Hiring and Turnover
export function populateDataHiringAndTurnover(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, site: row.site_id, male: {}, female: {}, other: {}, notDisclosed: {} };
    }

    const genderKey = row.gender === 'not disclosed' ? 'notDisclosed' : row.gender;
    formData[financialYear][genderKey][row.age_group] = {
      left: row.employees_left,
      recruits: row.new_recruits,
      total: row.total_employees
    };
  });

  return formData;
}

// Function to populate data for Parental Leave
export function populateParentalLeaveData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {
        financialYear: row.financial_year,
        site: row.site_id,
        entitled_to_leave: { male: 0, female: 0, other: 0, notDisclosed: 0 },
        took_leave: { male: 0, female: 0, other: 0, notDisclosed: 0 },
        returned_after_leave: { male: 0, female: 0, other: 0, notDisclosed: 0 },
        returned_still_employed: { male: 0, female: 0, other: 0, notDisclosed: 0 }
      };
    }

    const genderKey = row.gender === 'not disclosed' ? 'notDisclosed' : row.gender;
    formData[financialYear].entitled_to_leave[genderKey] = row.employees_entitled_to_parental_leave;
    formData[financialYear].took_leave[genderKey] = row.employees_took_parental_leave;
    formData[financialYear].returned_after_leave[genderKey] = row.employees_returned_after_parental_leave;
    formData[financialYear].returned_still_employed[genderKey] = row.employees_still_employed_12_months_after_return;
  });

  return formData;
}

// Function to populate data for Board and Management
export function populateBoardAndManagementData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {
        financialYear: row.financial_year,
        board_of_directors: { male: 0, female: 0, other: 0, notDisclosed: 0 },
        key_management_personnel: { male: 0, female: 0, other: 0, notDisclosed: 0 }
      };
    }

    const genderKey = row.gender === 'not disclosed' ? 'notDisclosed' : row.gender;
    formData[financialYear].board_of_directors[genderKey] = row.board_of_directors;
    formData[financialYear].key_management_personnel[genderKey] = row.key_management_personnel;
  });

  return formData;
}

// Function to populate data for Retirement Benefits
export function populateRetirementBenefitData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, benefits: {} };
    }

    formData[financialYear].benefits[row.benefit_type] = {
      employeesCovered: row.number_of_employees_covered,
      status: row.deducted_and_deposited_with_authority ? "yes" : "no"
    };
  });

  return formData;
}

// Function to populate data for Union Data
export function populateUnionData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, associationUnionEmployees: {}, associationUnionWorkers: {} };
    }

    formData[financialYear].associationUnionEmployees[row.gender] = row.no_of_employees_in_union;
    formData[financialYear].associationUnionWorkers[row.gender] = row.no_of_workers_in_union;
  });

  return formData;
}

// Function to populate data for Health and Safety or Skill Upgradation Trainings
export function populateTrainingData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, healthSafetyTrainings: {}, skillUpgradationTrainings: {} };
    }

    const genderKey = row.gender;
    formData[financialYear].healthSafetyTrainings[genderKey] = row.health_safety_training;
    formData[financialYear].skillUpgradationTrainings[genderKey] = row.skill_upgradation_training;
  });

  return formData;
}

// Function to populate data for Injury Data
export function populateInjuryData(data, employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = { financialYear: row.financial_year, fatalities: {}, workRelatedInjuries: {}, highConsequenceInjuries: {}, ltifr: {} };
    }

    const genderKey = row.gender;
    formData[financialYear].fatalities[genderKey] = row.fatalities;
    formData[financialYear].workRelatedInjuries[genderKey] = row.total_recordable_work_related_injuries;
    formData[financialYear].highConsequenceInjuries[genderKey] = row.high_consequence_work_related_injury_or_ill_health;
    formData[financialYear].ltifr[genderKey] = row.lost_time_injury_frequency_rate;
  });

  return formData;
}

// Function to populate data for Condition and Safety
export function populateComplaintsData(data, employmentCategory) {
  
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    // if (row.employement_category !== employmentCategory) return;

    const financialYear = row.financial_year;
    if (!formData[financialYear]) {
      formData[financialYear] = {  working_conditions: {}, health_and_safety: {} };
    }

    
      formData[financialYear].working_conditions = {
        filedduringyear: row.individuals_affected,
        pendingatendyear: row.individuals_or_family_rehabilitated_or_employed,
        remark: row?.additional_info?.remark
      }
      formData[financialYear].health_and_safety = {
        filedduringyear: row.individuals_affected,
        pendingatendyear: row.individuals_or_family_rehabilitated_or_employed,
        remark: row?.additional_info?.remark
      }
  });
// console.log(formData)
  return formData;
}

export function populateGovernance(data,employmentCategory) {
  const formData = {};
  const filteredData = data.filter(item => item.employement_category === employmentCategory);
  filteredData.forEach(row => {
    const financialYear = row.financial_year;
    const gender = row.gender;

    // Ensure that the financialYear exists in the formData object
    if (!formData[financialYear]) {
      formData[financialYear] = {
        financialYear: financialYear,
        site: row.site_id,
        board_of_directors: {
          male: 0,
          female: 0,
          other: 0,
          not_disclosed: 0,
        },
        key_management_personnel: {
          male: 0,
          female: 0,
          other: 0,
          not_disclosed: 0,
        },
      };
    }

    // Assign the values from the row to the corresponding gender and category
    if (row.board_of_directors !== undefined) {
      formData[financialYear].board_of_directors[gender] = row.board_of_directors;
    }
    if (row.key_management_personnel !== undefined) {
      formData[financialYear].key_management_personnel[gender] = row.key_management_personnel;
    }

    // If there's additional information, add it to the form data
    if (row.additional_info) {
      formData[financialYear].additional_info = row.additional_info;
    }
  });

  return formData;
}
