import React from 'react'

function WorkplaceSafetyMetrics() {
  return (
    <div>
      WorkplaceSafetyMetrics
    </div>
  )
}

export default WorkplaceSafetyMetrics
