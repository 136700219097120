// import React, { useState } from "react";

// const EmployeeForm = () => {
//   const [formData, setFormData] = useState({
//     site: "",
//     financialYear: "",
//     employees: {
//       permanentEmployees: { male: 0, female: 0, other: 0, notDisclosed: 0 },
//       temporaryEmployees: { male: 0, female: 0, other: 0, notDisclosed: 0 },
//       nonGuaranteedHoursEmployees: { male: 0, female: 0, other: 0, notDisclosed: 0 },
//       fullTimeEmployees: { male: 0, female: 0, other: 0, notDisclosed: 0 },
//       partTimeEmployees: { male: 0, female: 0, other: 0, notDisclosed: 0 },
//     },
//   });

//   const handleInputChange = (employeeType, field, value) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       employees: {
//         ...prevState.employees,
//         [employeeType]: {
//           ...prevState.employees[employeeType],
//           [field]: Number(value),
//         },
//       },
//     }));
//   };

//   const handleFormSubmit = (e) => {
//     e.preventDefault();
//     console.log("Submitted Data:", formData);
//     // Handle form submission logic here (API calls, etc.)
//   };

//   return (
//     <div className="w-full p-2 px-6">
    
//       <form onSubmit={handleFormSubmit}>
//         {/* <div className="flex justify-between w-full mb-4"> */}
//              {/* Site Selection */}
//         <div className="mb-4">
//           <label className="block text-gray-700 font-medium mb-2" htmlFor="site">
//             Choose Site
//           </label>
//           <select
//             id="site"
//             className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.site}
//             onChange={(e) => setFormData({ ...formData, site: e.target.value })}
//           >
//             <option value="">Select Site</option>
//             <option value="site1">Site 1</option>
//             <option value="site2">Site 2</option>
//           </select>
//         </div>
//         <div className="mb-4">
//           <label
//             className="block text-gray-700 font-medium mb-2"
//             htmlFor="financialYear"
//           >
//             Financial Year
//           </label>
//           <select
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.financialYear}
//             onChange={(e) =>
//                 setFormData({ ...formData, financialYear: e.target.value })
//               }
//           >
//             <option value="">Select financial year</option>
//             <option value="2024">2024</option>
           
//           </select>
//         </div>
//         {/* </div> */}
       

//         {/* Employee Types and Gender Distribution */}
//         <div className="mb-6">
//           <div className="grid grid-cols-5 gap-4 font-semibold text-gray-700">
//             <div>Employee Type</div>
//             <div>Male</div>
//             <div>Female</div>
//             <div>Other</div>
//             <div>Not Disclosed</div>
//           </div>

//           {/* Permanent Employees */}
//           <div className="grid grid-cols-5 gap-4 items-center mt-4">
//             <div className="text-gray-700 font-bold">Permanent Employees</div>
//             {["male", "female", "other", "notDisclosed"].map((field) => (
//               <input
//                 key={field}
//                 type="number"
//                 className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//                 value={formData.employees.permanentEmployees[field]}
//                 onChange={(e) =>
//                   handleInputChange("permanentEmployees", field, e.target.value)
//                 }
//               />
//             ))}
//           </div>

//           {/* Temporary Employees */}
//           <div className="grid grid-cols-5 gap-4 items-center mt-4">
//             <div className="text-gray-700 font-bold">Temporary Employees</div>
//             {["male", "female", "other", "notDisclosed"].map((field) => (
//               <input
//                 key={field}
//                 type="number"
//                 className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//                 value={formData.employees.temporaryEmployees[field]}
//                 onChange={(e) =>
//                   handleInputChange("temporaryEmployees", field, e.target.value)
//                 }
//               />
//             ))}
//           </div>

//           {/* Non-Guaranteed Hours Employees */}
//           <div className="grid grid-cols-5 gap-4 items-center mt-4">
//             <div className="text-gray-700 font-bold">Non-Guaranteed Hours Employees</div>
//             {["male", "female", "other", "notDisclosed"].map((field) => (
//               <input
//                 key={field}
//                 type="number"
//                 className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//                 value={formData.employees.nonGuaranteedHoursEmployees[field]}
//                 onChange={(e) =>
//                   handleInputChange("nonGuaranteedHoursEmployees", field, e.target.value)
//                 }
//               />
//             ))}
//           </div>

//           {/* Full-time Employees */}
//           <div className="grid grid-cols-5 gap-4 items-center mt-4">
//             <div className="text-gray-700 font-bold">Full-time Employees</div>
//             {["male", "female", "other", "notDisclosed"].map((field) => (
//               <input
//                 key={field}
//                 type="number"
//                 className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//                 value={formData.employees.fullTimeEmployees[field]}
//                 onChange={(e) =>
//                   handleInputChange("fullTimeEmployees", field, e.target.value)
//                 }
//               />
//             ))}
//           </div>

//           {/* Part-time Employees */}
//           <div className="grid grid-cols-5 gap-4 items-center mt-4">
//             <div className="text-gray-700 font-bold">Part-time Employees</div>
//             {["male", "female", "other", "notDisclosed"].map((field) => (
//               <input
//                 key={field}
//                 type="number"
//                 className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//                 value={formData.employees.partTimeEmployees[field]}
//                 onChange={(e) =>
//                   handleInputChange("partTimeEmployees", field, e.target.value)
//                 }
//               />
//             ))}
//           </div>
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-1 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EmployeeForm;
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialEmpDemographicInputs } from '../../../features/social/socialThunk';
import useApi from '../../../customHooks/useApi';

const EmployeeTypeFormPage = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const {employementType,siteData}=useSelector(state=>state.social)
  const generateYears = (startYear) => Array.from({ length: new Date().getFullYear() - startYear + 1 }, (_, i) => startYear + i).reverse();
  const api=useApi()
  const [formData,setFormData]=useState({})
  const dispatch=useDispatch()
  const employeeTypeConfig = {
    title: 'Employee Type Form',
    siteOptions: siteData, 
    financialYearOptions: generateYears(1997), 
    sections: [
      {
        key: 'employee_type',
        label: 'Employee Type',
        category_label: 'Gender',
        rows: [
          { key: 'permanent_employees', label: 'Permanent Employees' },
          { key: 'temporary_employees', label: 'Temporary Employees' },
          { key: 'non_guaranteed_hours_employees', label: 'Non-Guaranteed Hours Employees' },
          { key: 'full_time_employees', label: 'Full-time Employees' },
          { key: 'part_time_employees', label: 'Part-time Employees' },
        ],

        fields:  [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not_disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  function handleSubmitData(data) {
   
    setFormData(data) 
    dispatch(postSocialEmpDemographicInputs({api,formData:{...data,employement_type:employementType}}))
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={employeeTypeConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={employeeTypeConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeTypeFormPage;
