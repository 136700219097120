import React from 'react'

function GovernanceDiversity() {
  return (
    <div>
      GovernanceDiversity
    </div>
  )
}

export default GovernanceDiversity
