// import React, { useState } from 'react';

// const GovernanceDiversityForm = () => {
//   const initialData = {
//     financialYear: '',
//     boardOfDirectors: {
//       male: 0,
//       female: 0,
//       other: 0,
//       notDisclosed: 0,
//     },
//     keyManagementPersonnel: {
//       male: 0,
//       female: 0,
//       other: 0,
//       notDisclosed: 0,
//     },
//   };

//   const [formData, setFormData] = useState(initialData);

//   const handleInputChange = (category, gender, value) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       [category]: {
//         ...prevState[category],
//         [gender]: Number(value),
//       },
//     }));
//   };

//   const handleFinancialYearChange = (e) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       financialYear: e.target.value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted: ', formData);
//     // Add logic to handle form submission, like sending data to an API
//   };

//   const renderMetricRow = (category) => (
//     <div className="grid grid-cols-5 gap-4 mt-4">
//       <div className="text-gray-700 capitalize font-bold">{category.replace(/([A-Z])/g, ' $1')}</div>
//       {['male', 'female', 'other', 'notDisclosed'].map((gender) => (
//         <input
//           key={gender}
//           type="number"
//           className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//           value={formData[category][gender]}
//           onChange={(e) => handleInputChange(category, gender, e.target.value)}
//         />
//       ))}
//     </div>
//   );

//   return (
//     <div className="w-full p-2 px-6 h-full ">
    
//       <form onSubmit={handleSubmit}>
//         {/* Financial Year */}
//         <div className="mb-6">
//           <label className="block text-gray-700 ">Financial Year</label>
//           <select
//             className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//             value={formData.financialYear}
//             onChange={handleFinancialYearChange}
//           >
//             <option value="">Select financial year</option>
//             <option value="2024">2024</option>
           
//           </select>
//         </div>

//         {/* Governance Diversity Table */}
//         <div className="mb-6">
         
//           <div className="grid grid-cols-5 gap-4 font-semibold text-gray-700 mt-6">
//             <div>Metric</div>
//             <div>Male</div>
//             <div>Female</div>
//             <div>Other</div>
//             <div>Not Disclosed</div>
//           </div>

//           {/* Board of Directors */}
//           {renderMetricRow('boardOfDirectors')}
//           {/* Key Management Personnel */}
//           {renderMetricRow('keyManagementPersonnel')}
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-1 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default GovernanceDiversityForm;
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import useApi from '../../../customHooks/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialGovernanceDiversityInputs } from '../../../features/social/socialThunk';

const GovernanceDiversityForm = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [formData,setFormData]=useState({})
  const governanceDiversityConfig = {
    title: 'Governance Diversity Form',
    financialYearOptions: ['2022', '2023', '2024'], // Adjust the years as needed
    sections: [
      {
        key: 'governance_metrics',
        label: 'Governance Diversity Metrics',
        rows: [
          { key: 'board_of_directors', label: 'Board of Directors' },
          { key: 'key_management_personnel', label: 'Key Management Personnel' },
        ],
        fields: [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not_disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  
  function handleSubmitData(data) {
    dispatch(postSocialGovernanceDiversityInputs({api,formData:data,employementType}))
    setFormData(data) 
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={governanceDiversityConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={governanceDiversityConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default GovernanceDiversityForm;
