import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';

const EmployeeRehabilitationPage = () => {
    const [selectedTab, setSelectedTab] = useState('form');
    const [formData,setFormData]=useState({})
  const employeeRehabilitationConfig = {
    title: 'Employee Rehabilitation Form',
    // siteOptions: ['Site 1', 'Site 2', 'Site 3'], 
    financialYearOptions: ['2021-2022', '2022-2023', '2023-2024'], 
    sections: [
      {
        key: 'employee_rehabilitation',
        label: 'Employee Type',
        rows: [
          { key: 'employees', label: 'Employees' },
          { key: 'workers', label: 'Workers' },
        ], 
        fields: [
          { key: 'affected', label: 'Number of Individuals Affected', type: 'number' },
          { key: 'rehabilitated', label: 'Number of Individuals/Family Members Rehabilitated or Placed in Suitable Employment', type: 'number' },
        ],
      },
    ],
  };
  function handleSubmitData(data) {
    console.log(data);
    setFormData(data) 
    setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={employeeRehabilitationConfig} handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={employeeRehabilitationConfig} data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default EmployeeRehabilitationPage;
