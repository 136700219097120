import React from 'react';

const EmployeeBenefitsDisplay = ({ data,siteOptions}) => {
 const site=data?.site
 function handleDropdownChange(){

 }
  const renderBenefitRow = (benefitType, benefitData) => {
    return (
      <tr key={benefitType}>
        <td className="border border-gray-300 p-2">{benefitType}</td>
        <td className="border border-gray-300 p-2">{benefitData.employeesCovered}</td>
        <td className="border border-gray-300 p-2 capitalize">{benefitData.status}</td>
      </tr>
    );
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">{data?.financialYear || 'N/A'}</h2>
      {site&& <div className="mb-4 w-1/4 float-right">
          {/* <label className="block mb-2 font-medium">Site</label> */}
          <select
            value={site}
            onChange={(e) => handleDropdownChange('site', e.target.value)}
            className="border rounded p-2 w-full"
          >
            <option value="">Select Site</option>
            {siteOptions.map((site) => (
              <option key={site} value={site}>
                {site}
              </option>
            ))}
          </select>
        </div>}
      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="border border-gray-300 p-2">Benefit Type</th>
            <th className="border border-gray-300 p-2">Number of Employees covered</th>
            <th className="border border-gray-300 p-2">Deducted and deposited with the authority</th>
          </tr>
        </thead>
        <tbody>
          {/* Render rows for PF, Gratuity, and ESI */}
          {renderBenefitRow('PF', data?.benefits?.pf)}
          {renderBenefitRow('Gratuity', data?.benefits?.gratuity)}
          {renderBenefitRow('ESI', data?.benefits?.esi)}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeBenefitsDisplay;
