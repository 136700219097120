


import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import RetirementBenefitsForm from './RetirementBenefitsForm';
import EmployeeBenefitsDisplay from '../DataView/EmployeeBenefitsDisplay';
import { useDispatch, useSelector } from 'react-redux';
import useApi from '../../../customHooks/useApi';
import { postSocialRetirementBenefitsInputs } from '../../../features/social/socialThunk';

const RetirementBenefits = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  
  function handleSubmitData(data) {
dispatch(postSocialRetirementBenefitsInputs({api,formData:data,employementType}))
    setFormData(data) 
    // console.log(data)
    // setSelectedTab('display');
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <RetirementBenefitsForm  handleSubmitData={handleSubmitData} />;
      case "display":
        return <EmployeeBenefitsDisplay data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6'>
        {renderTab()}
    </div>
  );
};

export default RetirementBenefits;

