import React from "react";

const GenericTiles = ({ data, title, categoryHeaders, dataKey }) => {
  console.log(data);
  return (
    <div className="container mx-auto space-y-8 w-full">
      {Object.keys(data).map((year) => (
        <div key={year} className="border p-4 rounded bg-gray-100">
          <div className="flex justify-start gap-5">

          <h2 className="text-xl font-semibold mb-4 text-center">Financial Year-{` ${year}`}</h2>
          {data[year]["Full Time"]?.site && (
            <h2 className="text-xl font-semibold mb-4 text-center">Facility-{` ${data[year]["Full Time"]?.site}`}</h2>
          )}
          </div>
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="p-2 border border-gray-300">{dataKey}</th>
                {categoryHeaders.map((header) => (
                  <th
                    key={header}
                    className="p-2 border border-gray-300 text-center"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data[year]).map((category) => (
                <tr key={category} className="hover:bg-gray-100">
                  <td className="p-2 border border-gray-300 font-medium capitalize">
                    {category.replace(/_/g, " ")}
                  </td>
                  {categoryHeaders.map((header) => (
                    <td
                      key={`${category}-${header}`}
                      className="p-2 border border-gray-300 text-center"
                    >
                      {data[year][category][header.toLowerCase()] || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default GenericTiles;
