import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useApi from '../../../../customHooks/useApi'
import EmployeeTypeTiles from './Tiles'
import { aggregateDataByEmploymentType, populateComplaintsData, transformApiData } from '../../function/functionProcess'
import { resetSocialFormData } from '../../../../features/social/socialSlice'
import { fetchSocialComplaintsInputs, fetchSocialEmpWellBeingInputs } from '../../../../features/social/socialThunk'
import GenericTiles from './GenericTile'
import { transformEmpDemographicsData } from '../../../../features/social/functions/Functions'

function ComplaintTracking() {
  const {socialComplaintsRecords,employementType,socialFormData}=useSelector(state=>state.social)
  const dispatch=useDispatch()
  
  const api =useApi()
  useEffect(()=>{
  //  dispatch(resetSocialFormData({}))
dispatch(fetchSocialComplaintsInputs({api,params:{}}))
  },[])
  return (
    <div className='overflow-auto h-full w-full'>
   <GenericTiles data={populateComplaintsData(socialComplaintsRecords,employementType)} title="Employee Overview"
  categoryHeaders={["filedDuringYear", "pendingAtEndYear", "remark"]}
  dataKey="Complaint Type " />
    </div>
  )
}

export default ComplaintTracking
