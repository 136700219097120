// import React, { useState } from 'react';

// const PaternityForm = () => {
//   const [site,setsite]=useState([{
//     site:1,id:1
//   }])
//   const [financialYear,setFinancialYear]=useState([{
//     year:2022,id:1
//   }])
//   const initialData = {
//     site: '',
//     financialYear: '',
//     male: {
//       entitled: 0,
//       tookLeave: 0,
//       returned: 0,
//       stillEmployed: 0,
//     },
//     female: {
//       entitled: 0,
//       tookLeave: 0,
//       returned: 0,
//       stillEmployed: 0,
//     },
//     other: {
//       entitled: 0,
//       tookLeave: 0,
//       returned: 0,
//       stillEmployed: 0,
//     },
//     notDisclosed: {
//       entitled: 0,
//       tookLeave: 0,
//       returned: 0,
//       stillEmployed: 0,
//     },
//   };

//   const [formData, setFormData] = useState(initialData);

//   const handleInputChange = (gender, field, value) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       [gender]: {
//         ...prevState[gender],
//         [field]: Number(value),
//       },
//     }));
//   };

//   const handleSiteChange = (field, value) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       [field]: value,
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form Submitted: ', formData);
//     // Handle submission logic here (e.g., API call)
//   };
// const metrix=['Number of employees entitled to parental leave', 'Number of employees who took parental leave', 'Number of employees that returned to work in the reporting period after parental leave ended', 'Number of employees that returned to work after parental leave ended that were still employed 12 months after their return to work']
//   const renderTable = (gender) => {
//     return (
//       <>
//         <div className="grid grid-cols-5 gap-2 font-semibold text-gray-700 mt-2">
//           <div>Metric</div>
//           <div>Male</div>
//           <div>Female</div>
//           <div>Other</div>
//           <div>Not Disclosed</div>
//         </div>
       
//         {['entitled', 'tookLeave', 'returned', 'stillEmployed'].map((field,index) => (
//           <div className="grid grid-cols-5 gap-4 mt-4" key={field}>
//             <div className="font-semibold text-gray-700 flex justify-center items-center">{metrix[index]}</div>
//             {['male', 'female', 'other', 'notDisclosed'].map((gender) => (
//               <input
//                 key={gender}
//                 type="number"
//                 className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500 h-fit"
//                 value={formData[gender][field]}
//                 onChange={(e) =>
//                   handleInputChange(gender, field, e.target.value)
//                 }
//               />
//             ))}
//           </div>
//         ))}
//       </>
//     );
//   };

//   return (
//     <div className="w-full mx-auto p-4 h-full">
     
//       <form onSubmit={handleSubmit}>
//         {/* Site and Financial Year */}
//         <div className="grid grid-cols-2 gap-6 mb-6">
//           <div>
//             <label className="block text-gray-700">Site</label>
//             <select
//               className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.site}
//               onChange={(e) => handleSiteChange('site', e.target.value)}
//             >
//               <option value="">Select site</option>
//               {site.map(({site}) => (
//                 <option value={site} key={site}>{site}</option>
//               ))}
//             </select>
//           </div>
//           <div>
//             <label className="block text-gray-700">Financial Year</label>
//             <select
//               className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
//               value={formData.financialYear}
//               onChange={(e) => handleSiteChange('financialYear', e.target.value)}
//             >
//               <option value="">Select financial year</option>
//               {financialYear.map(({year}) => (
//                 <option value={year} key={year}>{year}</option>
//               ))}
//             </select>
//           </div>
//         </div>

//         {/* Parental Leave Table */}
//         <div className="mb-6">
//           {renderTable()}
//         </div>

//         {/* Submit Button */}
//         <div className="flex justify-end">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-1 focus:ring-green-500"
//           >
//             Submit
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default PaternityForm;
import React, { useState } from 'react';
import GenericForm from './GenericForm';
import DataDisplay from '../DataView/DataDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { postSocialPaternityInputs } from '../../../features/social/socialThunk';
import useApi from '../../../customHooks/useApi';

const PaternityForm = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const {employementType,siteData}=useSelector(state=>state.social)
  const api=useApi()
  const dispatch=useDispatch()
  const [formData,setFormData]=useState({})
  const parentalLeaveConfig = {
    title: 'Parental Leave Form',
    financialYearOptions: ['2022', '2023', '2024'], // Adjust the years as needed
    siteOptions: siteData,
    sections: [
      {
        key: 'metrics',
        label: 'Parental Leave Metrics',
        rows: [
          { key: 'entitled_to_leave', label: 'Number of employees entitled to parental leave' },
          { key: 'took_leave', label: 'Number of employees who took parental leave' },
          { key: 'returned_after_leave', label: 'Number of employees that returned to work in the reporting period after parental leave ended' },
          { key: 'returned_still_employed', label: 'Number of employees that returned to work after parental leave ended that were still employed 12 months after their return to work' },
        ],
        fields: [
          { key: 'male', label: 'Male', type: 'number' },
          { key: 'female', label: 'Female', type: 'number' },
          { key: 'other', label: 'Other', type: 'number' },
          { key: 'not_disclosed', label: 'Not Disclosed', type: 'number' },
        ],
      },
    ],
  };
  function handleSubmitData(data) {
    dispatch(postSocialPaternityInputs({api,formData:data,employementType

    }))
    setFormData(data) 
    // console.log(data)
  }
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <GenericForm config={parentalLeaveConfig

        } handleSubmitData={handleSubmitData} />;
      case "display":
        return <DataDisplay config={parentalLeaveConfig

        } data={formData}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full p-2 px-6 overflow-auto'>
        {renderTab()}
    </div>
  );
};

export default PaternityForm;
