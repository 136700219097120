
/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../../components/common/Header";
import UserIcon from "../../../app/assets/UserIcon.svg";
// import SocialIcon from "../../../app/assets/Social.svg";
import { ReactComponent as SocialIcon } from "../../../app/assets//Social.svg";
import { ReactComponent as PurchasedIcon } from "../../../app/assets/PurchasedIcon.svg";

import { ReactComponent as PencilIcon } from "../../../app/assets/PencilIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../app/assets/DeleteIcon.svg";
import { ReactComponent as AddIconBig } from "../../../app/assets/AddIconBig.svg";
import { ReactComponent as DownloadIcon } from "../../../app/assets/DownloadIcon.svg";
import Table from "../../../components/common/Table/Table";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  resetSocialFormData,
  setCurrentPage,
  setFilters,
  setSocialForm,
  setSocialRecordType,
  setItemsPerPage,
  setSocialFormData,
  setEmployementType,
} from "../../../features/social/socialSlice";
import {
  deleteSocialData,
  fetchSiteData,
  fetchSocialData,
} from "../../../features/social/socialThunk";
import TableMeta from "../../../components/common/Table/TableMeta";
import TablePagination from "../../../components/common/Table/TablePagination";
import { toast } from "react-toastify";
import useApi from "../../../customHooks/useApi";
import FilterComponent from "../../../components/common/FilterComponent";
import { useSearchParams } from "react-router-dom";
import SocialForm from "../../../components/social/SocialForm";
import SocialEditAndCopyForm from "../../../components/social/SocialEditAndCopyForm";
import EmployeeDemographics from "./TabContent/EmployeeDemographics";
import HiringAndTurnOver from "./TabContent/HiringAndTurnOver";
import Paternity from "./TabContent/Paternity";
import GovernanceDiversity from "./TabContent/GovernanceDiversity";
import EmployeeWellBeing from "./TabContent/EmployeeWellBeing";
import RetirementBenefits from "./TabContent/RetirementBenefits";
import UnionAssociationParticipation from "./TabContent/UnionAssociationParticipation";
import TrainingMetrics from "./TabContent/TrainingMetrics";
import WorkplaceSafetyMetrics from "./TabContent/WorkplaceSafetyMetrics";
import ComplaintTracking from "./TabContent/ComplaintTracking";
import EmployeeRehabilitationPage from "./TabContent/EmployeeRehabilitationPage";

const tabs = [
  {
    id: 1,
    title: "Employee Demographics",
    subtabs: [
      { id: 1, title: "Employees" },
      { id: 2, title: "Workers" },
      { id: 3, title: "Differently Abled Employees" },
      { id: 4, title: "Differently Abled Workers" },
    ],
  },
  {
    id: 2,
    title: "Hiring & Turnover",
    subtabs: [
      { id: 1, title: "Permanent Workers" },
      { id: 2, title: "Part-time Employees" },
    ],
  },
  {
    id: 3,
    title: "Paternity",
    subtabs: [
      { id: 1, title: "Permanent Employees" },
      { id: 2, title: "Permanent Workers" },
    ],
  },
  { id: 4, title: "Governance Diversity", subtabs: [] },
  {
    id: 5,
    title: "Employee Well Being",
    subtabs: [
      { id: 1, title: "Permanent Employees" },
      { id: 2, title: "Non-Permanent Employees" },
      { id: 3, title: "Permanent Workers" },
      { id: 4, title: "Non-Permanent Workers" },
    ],
  },
  {
    id: 6,
    title: "Retirement Benefits",
    subtabs: [
      { id: 1, title: "Permanent Employees" },
      { id: 2, title: "Permanent Workers" },
    ],
  },
  { id: 7, title: "Union/Association Participation", subtabs: [] },
  {
    id: 8,
    title: "Training",
    subtabs: [
      { id: 1, title: "Permanent Employees" },
      { id: 2, title: "Permanent Workers" },
    ],
  },
  {
    id: 9,
    title: "Safety Related Incidents",
    subtabs: [
      { id: 1, title: "Employees" },
      { id: 2, title: "Workers" },
    ],
  },
  {
    id: 10,
    title: "Complaints",
    subtabs: [
      { id: 1, title: "Employees" },
      { id: 2, title: "Workers" },
    ],
  },
  { id: 11, title: "Rehabilitated", subtabs: [] },
  { id: 12, title: "Contractual Employee Details", subtabs: [] },
  { id: 13, title: "OHS system coverage for Non-employees", subtabs: [] },
];

const SocialPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { read_only } = useSelector((state) => state.auth);
  const headingsToDataKeyMap = {
    " ": "",
    EVIDENCE: "evidence",
    STATUS: "status",
  };

  if (!read_only) {
    headingsToDataKeyMap.EDIT = "edit";
    headingsToDataKeyMap.DELETE = "delete";
  }

  const api = useApi();
  const {
    socialRecords,
    socialRecordType,
    itemsPerPage,
    totalPages,
    totalCount,
    currentPage,
    siteDataForFilters,
  } = useSelector((state) => state.social);

  const [files, setFiles] = useState(null);
  const [dataRows, setDataRows] = useState([]);
  const [isPurchaseFormOpen, setPurchaseFormOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);
  const [selectedSubTab, setSelectedSubTab] = useState(1);
  const dispatch = useDispatch();
  const [actionType, setActionType] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isEditCopyFormOpen, setIsEditCopyFormOpen] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    paginationEnabled: true,
    currentPage: 1,
    totalPage: 1,
    itemsPerPage: 10,
    handleItemsPerPage: null,
    handlePageChange: null,
  });
  const filters = [
    {
      name: "site",
      label: "Facility",
      options:
        siteDataForFilters &&
        siteDataForFilters.map((site) => ({
          value: site.id,
          label: site.site,
        })),
    },
  ];

  const handleEdit = (row) => {
    dispatch(resetSocialFormData());
    setActionType("edit");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleDelete = (row) => {
    if (row.id) {
      dispatch(deleteSocialData({ api, id: row.id }));
    } else {
      toast.error("Delete failed, please try again.");
    }
  };

  const handleCopy = (row) => {
    dispatch(resetSocialFormData());
    setActionType("copy");
    setSelectedRowData(row);
    setIsEditCopyFormOpen(true);
  };

  const handleCloseEditCopyForms = () => {
    setIsEditCopyFormOpen(false);
  };

  const switchTab = (tabId, subTabId = 1) => {
    setSelectedTab(tabId);
    setSelectedSubTab(subTabId);
  };

  const openPopupForm = () => {
    setPurchaseFormOpen(true);
  };

  const closePurchasePopupForm = () => {
    setPurchaseFormOpen(false);
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPage = (itemCount) => {
    dispatch(setItemsPerPage(itemCount));
  };

  const handleFileDownload = (filePath) => {
    if (filePath) {
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "filename.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast("No evidence attached");
    }
  };

  const generateCellHtml = (row, k) => {
    let cellHtml = null;
    switch (k) {
      case "edit":
        cellHtml = (
          <span className="w-full flex justify-center">
            <PencilIcon
              className="cursor-pointer"
              onClick={() => handleEdit(row)}
            />
          </span>
        );
        break;
      case "delete":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => handleDelete(row)}
            />
          </span>
        );
        break;
      case "status":
        cellHtml = (
          <span className="w-full flex justify-center">
            <span
              className={`px-2 py-1 border border-transparent rounded-md ${
                row[k] === "Accepted"
                  ? "bg-green-200 text-green-600"
                  : row[k] === "Submitted"
                  ? "bg-blue-200 text-blue-600"
                  : "bg-red-200 text-red-600"
              }`}
            >
              {row[k]}
            </span>
          </span>
        );
        break;
      case "evidence":
        cellHtml = (
          <span className="w-full flex justify-center">
            <DownloadIcon
              className="cursor-pointer fill-green-500"
              onClick={() => handleFileDownload(row[k])}
            />
          </span>
        );
        break;
      default:
        cellHtml = (
          <span className="flex justify-center">
            <p>{row[k]}</p>
          </span>
        );
    }
    return cellHtml;
  };



  useEffect(() => {
    if (!files && socialRecordType && currentPage && itemsPerPage) {
      dispatch(
        fetchSocialData({
          api,
          socialRecordType,
          page: currentPage,
          limit: itemsPerPage,
        })
      );
    }
  }, [socialRecordType, currentPage, itemsPerPage]);

  function rander() {
    switch (selectedTab) {
      case 1:
        return <EmployeeDemographics />;
      case 2:
        return <HiringAndTurnOver />;
      case 3:
        return <Paternity/>;
      case 4:
        return <GovernanceDiversity/>;
      case 5:
        return <EmployeeWellBeing/>;
      case 6:
        return <RetirementBenefits />;
      case 7:
        return <UnionAssociationParticipation />;
      case 8:
        return <TrainingMetrics />;
      case 9:
        return <WorkplaceSafetyMetrics />;
      case 10:
        return <ComplaintTracking />;
      case 11:
        return <EmployeeRehabilitationPage />;

      default:
        return null;
    }
  }
  useEffect(()=>{
    const subTabname=tabs.find(i=>i.id===selectedTab)?.subtabs?.find(i=>i.id===selectedSubTab)?.title
    dispatch(setEmployementType(subTabname))
    dispatch(resetSocialFormData())
   },[selectedSubTab])
   useEffect(()=>{
dispatch(fetchSiteData({api}))
   },[])
  return (
    <div className="flex flex-col main-container w-full h-screen">
      <Header pageTitle="Social" PageIcon={SocialIcon} />
      <div className="bg-gray-100 h-full w-full p-4">
        <div className="flex  justify-between items-center text-xs">
          <div className="flex flex-wrap justify-start gap-2 text-center items-center w-full ">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`flex border items-center rounded-md mr-4 cursor-pointer border-green-500 px-1 ${
                  selectedTab === tab.id
                    ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                    : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
                }`}
                onClick={() => switchTab(tab.id)}
              >
                <PurchasedIcon />
                <span className="px-2">{tab.title}</span>
              </button>
            ))}
            <div className="flex-1">

        <FilterComponent
          filters={filters}
          onFilterChange={(filterValues) => {
            dispatch(setFilters(filterValues));
          }}
        />
            </div>
          </div>
         
        </div>
        <div className="flex flex-col border border-gray-300 rounded-md mt-[10px] relative">
          
          {tabs.find((tab) => tab.id === selectedTab) && (
            <div className="flex flex-row w-full h-12 justify-start items-center px-2 text-xs">
              {tabs
                .find((tab) => tab.id === selectedTab)
                .subtabs.map((subTab) => (
                  <button
                    key={subTab.id}
                    className={`flex flex-row justify-center items-center min-w-48 h-8 cursor-pointer border border-green-600 rounded-md mr-4 ${
                      selectedSubTab === subTab.id
                        ? "border-green-600 focus:bg-green-600 text-white white-svg bg-green-600"
                        : "text-green-600 bg-white border-gray-100 hover:bg-green-600 hover:text-white hover-white-svg"
                    }`}
                    onClick={() => switchTab(selectedTab, subTab.id)}
                  >
                    <span className="px-2 ">{subTab.title}</span>
                  </button>
                ))}
            </div>
          )}
          <>
          <div className="h-[60.5vh] border-t border-gray-300 py-2">

         {rander()}
          </div>
          {/* <Table
              height="60.5vh"
              headings={Object.keys(headingsToDataKeyMap)}
              dataRows={dataRows}
              paginationObject={{
                paginationEnabled: true,
                currentPage: currentPage,
                totalPages: totalPages,
                itemsPerPage: itemsPerPage,
                handleItemsPerPage: handleItemsPerPage,
                handlePageChange: handlePageChange,
              }}
            /> */}
            {!read_only && (
              <div className="flex  w-fit py-4 px-2 absolute bottom-8 right-6 cursor-pointer">
                <AddIconBig onClick={() => openPopupForm(socialRecordType)} />
              </div>
            )}
            {paginationConfig &&
              paginationConfig?.paginationEnabled &&
              paginationConfig?.handleItemsPerPage &&
              typeof paginationConfig?.handleItemsPerPage === "function" &&
              paginationConfig?.handlePageChange &&
              typeof paginationConfig?.handlePageChange === "function" && (
                <TablePagination
                  paginationObject={{
                    paginationEnabled: true,
                    currentPage: currentPage,
                    totalPages: totalPages,
                    itemsPerPage: itemsPerPage,
                    handleItemsPerPage: handleItemsPerPage,
                    handlePageChange: handlePageChange,
                  }}
                />
              )}
          </>
        </div>
        {isPurchaseFormOpen && (
          <SocialForm closePopup={closePurchasePopupForm} tabId={selectedTab} subTabId={selectedSubTab} tabName={tabs.find(i=>i.id===selectedTab).title} subTabname={tabs.find(i=>i.id===selectedTab)?.subtabs?.find(i=>i.id===selectedSubTab)?.title} />
        )}
        {isEditCopyFormOpen && (
          <SocialEditAndCopyForm
            actionType={actionType}
            rowData={selectedRowData}
            closeForm={handleCloseEditCopyForms}
          />
        )}
      </div>
    </div>
  );
};

export default SocialPage;

