

import React, { useState } from 'react';


import ContractualEmployeeForm from './ContractualEmployeeForm';
import EmployeeDataDisplay from '../DataView/HiringAndTurnover';

const ContractualEmployee = () => {
  const [selectedTab, setSelectedTab] = useState('form');
  const [formData,setFormData]=useState({})

  
  function handleSubmitData(data) {
    console.log(data);
    setFormData(data) 
    setSelectedTab('display');
  }
 const  siteOptions= ['Site 1', 'Site 2', 'Site 3']
  const renderTab = () => {
    switch (selectedTab) {
      case "form":
        return <ContractualEmployeeForm handleSubmitData={handleSubmitData} siteOptions={siteOptions}/>;
      case "display":
        return <EmployeeDataDisplay data={formData} siteOptions={siteOptions}/>;
      default:
        return null;
    }
  };
  return (
    <div className='h-full w-full overflow-auto p-2 px-6 py-3'>
        {renderTab()}
    </div>
  );
};

export default ContractualEmployee;

